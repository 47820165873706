import React, { useState } from 'react'
import axios from 'axios'

export const Newsletter = () => {
    const [email, setEmail] = useState(null)
    const [success, setSuccess] = useState(false)

    const onSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault()

        try {
            await axios.post('/api/email?action=subscribe', { email })
            setSuccess(true)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className="bg-slate-50 mt-8">
            <div className="max-w-7xl mx-auto py-12 px-4  lg:flex lg:items-center">
                <div className="lg:w-0 lg:flex-1">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                        Newsletter
                    </h2>

                    {success ? null : (
                        <p className="mt-3 max-w-3xl text-lg text-gray-500">
                            Doresti sa te anuntam cand avem promotii? Inscrie-te la
                            newsletter-ul nostru si iti vom trimite un e-mail.
                        </p>
                    )}
                </div>
                <div className="mt-8 lg:mt-0 lg:ml-8">
                    {success ? (
                        <div>
                            <p className="mt-3 max-w-3xl text-lg text-gray-500">
                                Te-ai abonat cu succes la newsletter-ul nostru.
                            </p>
                        </div>
                    ) : (
                        <form className="sm:flex" onSubmit={onSubmit}>
                            <label htmlFor="email-address" className="sr-only">
                                Email address
                            </label>
                            <input
                                id="email-address"
                                name="email-address"
                                type="email"
                                autoComplete="email"
                                required
                                className="w-full px-5 py-3 border border-gray-300 shadow-sm placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs rounded-md"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                                <button
                                    type="submit"
                                    className="w-full flex items-center justify-center py-3 px-5 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Abonare
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Newsletter
